<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            统计({{name}})
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" dark small @click="$router.back('-1')">
              返回
            </v-btn>
          </v-card-title>
          <v-card-subtitle>
            <p style="font-weight: bold;font-size:14px;margin-top:10px;">
              <span>激活设备:{{total.device||0}}</span>&nbsp;&nbsp;
              <span>注册账号:{{total.account||0}}</span>&nbsp;&nbsp;
              <span>成交订单:{{total.order||0}}</span>&nbsp;&nbsp;
              <span>总金额:{{(total.fee||0)/100}}元</span>
            </p>
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <ve-line :data="deviceData" judge-width></ve-line>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <ve-line :data="accountData" judge-width :colors="['#c23531']"></ve-line>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <ve-line :data="orderData" judge-width :colors="['#61a0a8']"></ve-line>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <ve-line :data="feeData" judge-width :colors="['#91c7ae']"></ve-line>
              </v-col>
            </v-row>
      
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>
  </v-container>

</template>
<script>
  import { post } from '@/facade'
  export default {
    data() {
      return {
        form: {},
        name: "",
        deviceData: {
          columns: ['日期', '激活设备'],
          rows: []
        },
        accountData: {
          columns: ['日期', '注册账号'],
          rows: []
        },
        orderData: {
          columns: ['日期', '成交订单'],
          rows: []
        },
        feeData: {
          columns: ['日期', '总金额'],
          rows: []
        },
        total: {}
      }
    },
    async created() {
      this.form.nid = this.$route.query.id
      this.name = this.$route.query.name
      if (this.$route.query.sid) this.form.sid = this.$route.query.sid
      await this.getCollection()
    },
    methods: {
      async getCollection() {
        const { code, list } = await post('/vnet/statistics', this.form)
        if (code === 'ok') {
          this.total = list[0]
          let arr = list.filter(item => item.date != 'all')
          arr.sort((a, b) => new Date(a['日期']).getTime() - new Date(b['日期']).getTime())
          this.deviceData.rows = arr.map(item => ({ '日期': item.date, '激活设备': item.device || 0 }));
          this.accountData.rows = arr.map(item => ({ '日期': item.date, '注册账号': item.account || 0 }));
          this.orderData.rows = arr.map(item => ({ '日期': item.date, '成交订单': item.order || 0 }));
          this.feeData.rows = arr.map(item => ({ '日期': item.date, '总金额': item.fee || 0 }));
        }
      }
    }
  }
</script>